<template>
  <AppHeader />
    <Suspense>
      <template #default>
        <Sounds :id="id"/>
      </template>
      <template #fallback>
        <Spinner />
      </template>
    </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
import Spinner from "@/components/Spinners/Spinner.vue";
import { defineAsyncComponent, Suspense } from "vue";
const Sounds = defineAsyncComponent(() =>
  import('@/components/Pages/Sounds.vue')
)
export default {
  name: "Sound",
  props: {
    id: String
  },
  components: {
    AppHeader,
    AppFooter,
    Sounds,
    Suspense,
    Spinner
  },
};
</script>

<style>
</style>